// locales/index.js
const accountEn = require("./en/account.json");
const accountResetPasswordSentEn = require("./en/accountResetPasswordSent.json");
const commonEn = require("./en/common.json");
const errorEn = require("./en/error.json");
const expiredEn = require("./en/expired.json");
const formValidationEn = require("./en/formValidation.json");
const loginEn = require("./en/login.json");
const notFoundEn = require("./en/404.json");
const preferencesEn = require("./en/preferences.json");
const pricingEn = require("./en/pricing.json");
const receiverEn = require("./en/receiver.json");
const receiverSessionFilesEn = require("./en/receiverSessionFiles.json");
const registerEn = require("./en/register.json");
const registerExpiredEn = require("./en/registerExpired.json");
const registerSuccessEn = require("./en/registerSuccess.json");
const registerVerifyEn = require("./en/registerVerify.json");
const resetPasswordEn = require("./en/resetPassword.json");
const resetPasswordExpiredEn = require("./en/resetPasswordExpired.json");
const resetPasswordResetEn = require("./en/resetPasswordReset.json");
const resetPasswordSentEn = require("./en/resetPasswordSent.json");
const resetPasswordSuccessEn = require("./en/resetPasswordSuccess.json");
const senderEn = require("./en/sender.json");
const ssoEn = require("./en/sso.json");
const successEn = require("./en/success.json");
const uploadEn = require("./en/upload.json");
const virusDetectedEn = require("./en/virusDetected.json");
const workspacesEn = require("./en/workspaces.json");

const resources = {
  en: {
    account: accountEn,
    accountResetPasswordSent: accountResetPasswordSentEn,
    common: commonEn,
    error: errorEn,
    expired: expiredEn,
    formValidation: formValidationEn,
    login: loginEn,
    notFound: notFoundEn,
    preferences: preferencesEn,
    pricing: pricingEn,
    receiver: receiverEn,
    receiverSessionFiles: receiverSessionFilesEn,
    register: registerEn,
    registerExpired: registerExpiredEn,
    registerSuccess: registerSuccessEn,
    registerVerify: registerVerifyEn,
    resetPassword: resetPasswordEn,
    resetPasswordExpired: resetPasswordExpiredEn,
    resetPasswordReset: resetPasswordResetEn,
    resetPasswordSent: resetPasswordSentEn,
    resetPasswordSuccess: resetPasswordSuccessEn,
    sender: senderEn,
    sso: ssoEn,
    success: successEn,
    upload: uploadEn,
    virusDetected: virusDetectedEn,
    workspaces: workspacesEn,
  },
};

module.exports = resources;
